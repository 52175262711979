<template>
  <footer class="footer border-top">
    <b-row>
      <b-col>
        <b-img
          src="/assets/awh-logo-sm.png"
          class="footer-logo mb-5"
          alt="Logo"
          fluid
        />
      </b-col>
      <b-col class="d-none d-lg-block">
        <h5>Beratung</h5>
        <ul class="list-unstyled">
          <li
            v-for="service in getChildsOfSite('beratung')"
            :key="service.name"
          >
            <a :href="getSitePath(service.name)">{{
              service.label.replace("[shy]", "&shy;")
            }}</a>
          </li>
        </ul>
      </b-col>
      <b-col class="d-none d-lg-block">
        <h5>Coaching</h5>
        <ul class="list-unstyled">
          <li
            v-for="service in getChildsOfSite('coaching')"
            :key="service.name"
          >
            <a :href="getSitePath(service.name)">{{
              service.label.replace("[shy]", "&shy;")
            }}</a>
          </li>
        </ul>
      </b-col>
      <b-col>
        <h5>Über</h5>
        <ul class="list-unstyled">
          <li><a href="/ueber">Über uns</a></li>
          <li><a href="/impressum">Impressum</a></li>
          <li><a href="/datenschutz">Datenschutz</a></li>
          <li><a href="/sitemap.xml">Sitemap</a></li>
        </ul>
      </b-col>
    </b-row>
    <div class="text-lg-right footer-bottom-text mt-4 mt-lg-1">
      Diese Seite ist geschützt durch
      <a href="https://www.hCaptcha.com">hCaptcha</a>. Es gelten die
      <a href="https://www.hcaptcha.com/privacy">Datenschutzbestimmungen</a> und
      <a href="https://www.hcaptcha.com/terms">Nutzungsbedingungen</a> von
      hCaptcha.
    </div>
  </footer>
</template>

<script>
import { getChildsOfSite, getSitePath } from "../config/site-structure";
export default {
  name: "TheFooter",
  props: {},
  methods: { getChildsOfSite, getSitePath },
};
</script>

<style scoped>
.footer-logo {
  max-width: 200px;
  height: auto;
}
.footer {
  margin-top: 5rem;
  padding: 2rem;
  text-align: left;
}

.footer-bottom-text {
  color: #929aa1;
}
.footer-bottom-text > a {
  color: #6b6f72;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-unstyled a {
  color: #6c757d !important;
}
.list-unstyled a:hover {
  text-decoration: underline;
}
</style>