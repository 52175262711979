<template>
  <b-navbar
    variant="faded"
    type="light"
    sticky
    class="navbar navbar-expand-lg"
    toggleable="lg"
  >
    <b-navbar-brand href="/">
      <b-img src="/assets/awh-logo-sm.png" class="nav-logo" alt="Logo" />
    </b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown right no-caret>
          <template #button-content>
            <span>Beratung</span>
            <b-icon
              icon="briefcase-fill"
              class="mx-2 d-lg-none"
              scale="0.8"
            ></b-icon>
          </template>
          <div
            class="
              d-flex
              p-2 p-lg-3
              flex-wrap flex-lg-nowrap
              justify-content-around
            "
          >
            <div
              v-for="service in getChildsOfSite('beratung')"
              class="d-flex flex-column m-2 m-lg-3 service-category"
              :key="service.name"
            >
              <div class="font-weight-bold text-lg-center mb-1 mb-lg-3">
                {{ service.label.replace("[shy]", "&shy;") }}
              </div>
              <div>
                <b-dropdown-item
                  v-for="subService in getChildsOfSite(service.name)"
                  :key="subService.name"
                  :href="getSitePath(subService.name)"
                >
                  <b-icon :icon="subService.icon" class="mr-3"></b-icon>
                  <span>{{
                    (subService.label_min || subService.label).replace(
                      "[shy]",
                      "&shy;"
                    )
                  }}</span>
                </b-dropdown-item>
              </div>
            </div>
          </div>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown right no-caret>
          <template #button-content>
            <span>Coaching</span>
            <b-icon
              icon="easel-fill"
              class="mx-2 d-lg-none"
              scale="0.8"
            ></b-icon>
          </template>
          <b-dropdown-item
            v-for="service in getChildsOfSite('coaching')"
            :key="service.name"
            :href="getSitePath(service.name)"
            class="text-center"
            >{{ service.label.replace("[shy]", "&shy;") }}</b-dropdown-item
          >
        </b-nav-item-dropdown>
        <b-nav-item href="/erfa">
          <span>Erfa</span>
          <b-icon icon="chat-fill" class="mx-2 d-lg-none" scale="0.8"></b-icon>
        </b-nav-item>
        <b-nav-item href="/ueber">
          <span>Über uns</span>
          <b-icon icon="person-fill" class="mx-2 d-lg-none"></b-icon>
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { getChildsOfSite, getSitePath } from "../config/site-structure";
export default {
  name: "TheHeader",
  props: {},
  methods: { getChildsOfSite, getSitePath },
};
</script>

<style scoped>
.navbar {
  background-color: rgba(199, 199, 199, 0.925);
}
.nav-logo {
  width: auto;
  height: 50px;
  margin-right: 20px;
}
.service-category {
  max-width: 400px;
}
.navbar-collapse.show,
.navbar-collapse.collapsing {
  text-align: right;
  max-height: 600px;
  overflow-y: auto;
}
</style>