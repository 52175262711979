const sites = {
    // 1. BERATUNG
    "beratung": {
        parent: null,
        name: "beratung",
        label: "Beratung",
        img_min: "",
        img: "",
        icon: ""
    },

    // 1.1. BETRIEBSWIRTSCHAFT
    "betriebswirtschaft": {
        parent: "beratung",
        name: "betriebswirtschaft",
        label: "Betriebs[shy]wirtschaftliche Beratung",
        img_min: "/assets/visuals/beratung-wirtschaft.png",
        img: "",
        icon: ""
    },
    "bwa-analyse": {
        parent: "betriebswirtschaft",
        name: "bwa-analyse",
        label: "BWA Analyse",
        img_min: "",
        img: "",
        icon: "search"
    },
    "benchmarking": {
        parent: "betriebswirtschaft",
        name: "benchmarking",
        label: "Benchmarking",
        img_min: "",
        img: "",
        icon: "bar-chart"
    },
    "einkaufsoptimierung": {
        parent: "betriebswirtschaft",
        name: "einkaufsoptimierung",
        label: "Einkaufs[shy]optimierung",
        img_min: "",
        img: "",
        icon: "cart4"
    },
    "grosshandelskonditionen": {
        parent: "betriebswirtschaft",
        name: "grosshandelskonditionen",
        label: "Überprüfung und Verhandlung von Großhandels[shy]konditionen",
        label_min: "Großhandels[shy]konditionen",
        img_min: "",
        img: "",
        icon: "layers",
        contact: "heppelmann"
    },
    "personalvermittlung": {
        parent: "betriebswirtschaft",
        name: "personalvermittlung",
        label: "Personal[shy]vermittlung",
        img_min: "",
        img: "",
        icon: "person-check"
    },
    "kostensenkung": {
        parent: "betriebswirtschaft",
        name: "kostensenkung",
        label: "Kostensenkung",
        img_min: "",
        img: "",
        icon: "graph-down"
    },

    // 1.2. MARKETING
    "marketing": {
        parent: "beratung",
        name: "marketing",
        label: "Marketing Beratung",
        img_min: "/assets/visuals/marketing.png",
        img: "",
        icon: ""
    },
    "standortanalyse": {
        parent: "marketing",
        name: "standortanalyse",
        label: "Standortanalyse",
        img_min: "",
        img: "",
        icon: "geo-alt"
    },
    "strategieentwicklung": {
        parent: "marketing",
        name: "strategieentwicklung",
        label: "Strategie[shy]entwicklung",
        img_min: "",
        img: "",
        icon: "easel"
    },
    "massnahmen": {
        parent: "marketing",
        name: "massnahmen",
        label: "Maßnahmen zur Umsatz- und Ergebnis[shy]verbesserung",
        label_min: "Umsatz[shy]verbesserung",
        img_min: "",
        img: "",
        icon: "graph-up"
    },
    "einrichtung": {
        parent: "marketing",
        name: "einrichtung",
        label: "Einrichtung",
        img_min: "",
        img: "",
        icon: "shop"
    },
    "pr": {
        parent: "marketing",
        name: "pr",
        label: "PR Beratung",
        img_min: "",
        img: "",
        icon: "newspaper"
    },
    "automaten": {
        parent: "marketing",
        name: "automaten",
        label: "Kommissionier[shy]automaten",
        img_min: "",
        img: "",
        icon: "inboxes"
    },
    "qm": {
        parent: "marketing",
        name: "qm",
        label: "Qualitätsmanagement ",
        img_min: "",
        img: "",
        icon: "award"
    },

    // 1.3. Standortgründung/Apothekenverkauf
    "standortgruendung": {
        parent: "beratung",
        name: "standortgruendung",
        label: "Standortgründung und Apotheken[shy]verkauf",
        img_min: "/assets/visuals/apotheke-verkauf.png",
        img: "",
        icon: ""
    },
    "standortbewertung": {
        parent: "standortgruendung",
        name: "standortbewertung",
        label: "Standort[shy]bewertung",
        img_min: "",
        img: "",
        icon: "file-spreadsheet"
    },
    "verkaufspreis": {
        parent: "standortgruendung",
        name: "verkaufspreis",
        label: "Ermittlung Verkaufspreis",
        img_min: "",
        img: "",
        icon: "tag"
    },
    "kaufpreisverhandlungen": {
        parent: "standortgruendung",
        name: "kaufpreisverhandlungen",
        label: "Kaufpreis[shy]verhandlungen",
        img_min: "",
        img: "",
        icon: "cash-stack"
    },
    "kaeufersuche": {
        parent: "standortgruendung",
        name: "kaeufersuche",
        label: "Käufersuche",
        img_min: "",
        img: "",
        icon: "person-bounding-box"
    },
    "finanzierung": {
        parent: "standortgruendung",
        name: "finanzierung",
        label: "Finanzierung",
        img_min: "",
        img: "",
        icon: "credit-card"
    },

    // 2. Coaching
    "coaching": {
        parent: null,
        name: "coaching",
        label: "Coaching",
        img_min: "",
        img: "",
        icon: ""
    },
    // 2.1. Existenzgründer
    "existenzgruender": {
        parent: "coaching",
        name: "existenzgruender",
        label: "Existenzgründer",
        description: "Zusammenarbeit mit unseren Netzwerkpartnern.",
        img_min: "",
        img: "",
        icon: ""
    },
    // 2.2. Existenzgründer
    "apothekenbetrieb": {
        parent: "coaching",
        name: "apothekenbetrieb",
        label: "Apothekenbetrieb",
        description: "Den täglichen Apothekenbetrieb optimal gestalten und eine persönliche Work-Life-Balance schaffen.",
        img_min: "",
        img: "",
        icon: ""
    },
    // 2.3. Führung
    "apothekenfuehrung": {
        parent: "coaching",
        name: "apothekenfuehrung",
        label: "Apothekenführung",
        description: "Die richtige Führung von Apotheke und Mitarbeitern lernen.",
        img_min: "",
        img: "",
        icon: ""
    },
    // 3. Apothekenvertretung
    "vertretung": {
        name: "vertretung",
        label: "Apotheken[shy]vertretung",
        hidden: true,
        contact: "wigand"
    },
};

function listSites() {
    return Object.values(sites);
}

function getSiteByName(name) {
    if (!name)
        return null;
    if (sites[name])
        return sites[name]
    return null;
}

function getSitePath(name) {
    var site = getSiteByName(name);
    if (!site)
        return null;
    return '/leistungen/' + name;
}

function getSiteByPath(path) {
    var pathSegements = path.split('/');
    return getSiteByName(pathSegements[pathSegements.length - 1]);
}

function getParentSites() {
    return Object.keys(sites)
        .map(siteName => sites[siteName])
        .filter(site => site.parent === null);
}

function getChildsOfSite(name) {
    return Object.keys(sites)
        .map(siteName => sites[siteName])
        .filter(site => site.parent === name);
}

module.exports = {
    sites: sites,
    listSites: listSites,
    getSiteByName: getSiteByName,
    getSiteByPath: getSiteByPath,
    getSitePath: getSitePath,
    getParentSites: getParentSites,
    getChildsOfSite: getChildsOfSite
}