<template>
  <div id="app" data-view>
    <TheCookieConsent v-if="!isPrerenderer" />
    <TheHeader />
    <div>
      <router-view />
      <TheFooter />
    </div>
  </div>
</template>

<script>
import TheHeader from "./components/TheHeader.vue";
import TheFooter from "./components/TheFooter.vue";
import TheCookieConsent from "./components/TheCookieConsent.vue";

export default {
  name: "App",
  components: {
    TheHeader,
    TheFooter,
    TheCookieConsent,
  },
  data: () => {
    return {
      isPrerenderer: window.__PRERENDER_INJECTED,
    };
  },
  metaInfo: {
    title: "Apothekenberatung Wigand-Heppelmann",
    titleTemplate: "%s - AWH",
  },
};
</script>

<style>
html {
  scroll-behavior: smooth;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.element-container.no-padding {
  padding-left: 0px;
  padding-right: 0px;
}
.element-container {
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.text-container {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.button {
  background-color: #e20019;
  border-color: #e20019;
}
.button:hover {
  background-color: #b60015;
  border-color: #b60015;
}
.button:focus {
  background-color: #b60015;
  border-color: #b60015;
}
.button:active {
  background-color: #b60015;
  border-color: #b60015;
}
.button-outline {
  border: none;
  background-color: transparent;
  color: #e20019;
}
.button-outline:hover {
  border: none;
  background-color: transparent;
  color: #b60015;
}
</style>