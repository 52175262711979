<template>
  <div>
    <b-modal
      id="cookie-modal"
      scrollable
      centered
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      title="Privatsphäre Einstellungen"
      size="lg"
    >
      <div class="m-1">
        Wir nutzen Cookies und ähnliche Technologien, die zum Betrieb der
        Website erforderlich sind. Zusätzliche Cookies werden nur mit Ihrer
        Zustimmung verwendet. Wir verwenden die zusätzlichen Cookies, um
        Analysen der Website-Nutzung durchzuführen und Marketingmaßnahmen auf
        ihre Effizienz zu prüfen. Diese Analysen finden statt, um Ihnen ein
        besseres Nutzungserlebnis der Website zu bieten. Es steht Ihnen frei,
        Ihre Zustimmung jederzeit zu geben, zu verweigern oder zurückzuziehen.
        Sie können der Verwendung von Cookies durch uns zustimmen, indem Sie auf
        "Einverstanden" klicken. Für weitere Informationen darüber, welche Daten
        gesammelt und wie sie an unsere Partner weitergegeben werden, lesen Sie
        bitte unsere <a href="/datenschutz">Datenschutzerklärung</a>.
      </div>
      <template #modal-footer="{}">
        <b-button @click="reject()" class="button-outline"
          >Ich bin nicht einverstanden</b-button
        >
        <b-button @click="accept()" class="button">Einverstanden</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "TheCookieConsent",
  props: {},
  methods: {
    accept() {
      this.setCookieConsent(true);
      this.closeCookieModal();
      this.loadAfterConfirmation();
    },
    reject() {
      this.setCookieConsent(false);
      this.closeCookieModal();
      this.loadAfterConfirmation();
    },
    setCookieConsent(accepted) {
      this.$cookies.set("cookie-consent", String(accepted));
    },
    closeCookieModal() {
      this.$bvModal.hide("cookie-modal");
    },
    loadAfterConfirmation() {
      // hCaptcha should be loaded even if cookies were rejected (mandatory)
      let hcaptcha = document.createElement("script");
      hcaptcha.setAttribute("src", "https://js.hcaptcha.com/1/api.js?hl=de");
      hcaptcha.setAttribute("async", "");
      hcaptcha.setAttribute("defer", "");
      document.head.appendChild(hcaptcha);

      // Check for cookie consent
      if (
        this.$cookies.isKey("cookie-consent") &&
        this.$cookies.get("cookie-consent") == "true"
      ) {
        // Load Google Analytics
        let googleAnalytics = document.createElement("script");
        googleAnalytics.setAttribute(
          "src",
          "https://www.googletagmanager.com/gtag/js?id=G-B25TDQ1QS0"
        );
        googleAnalytics.setAttribute("async", "");
        document.head.appendChild(googleAnalytics);
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag("js", new Date());
        gtag("config", "G-B25TDQ1QS0");
      }
    },
  },
  mounted() {
    // Check if cookies were accepted or prerender process is running
    if (!this.$cookies.isKey("cookie-consent"))
      this.$bvModal.show("cookie-modal");
    else this.loadAfterConfirmation();
  },
};
</script>

<style scoped>
</style>