const { listSites } = require('../config/site-structure');

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
    },
    {
        path: '/ueber',
        name: 'Über',
        component: () => import(/* webpackChunkName: "about" */ '../views/Ueber.vue')
    },
    {
        path: '/impressum',
        name: 'Impressum',
        component: () => import(/* webpackChunkName: "impressum" */ '../views/Impressum.vue')
    },
    {
        path: '/datenschutz',
        name: 'Datenschutz',
        component: () => import(/* webpackChunkName: "datenschutz" */ '../views/Datenschutz.vue')
    },
    {
        path: '/leistungen',
        name: 'Leistungen',
        component: () => import(/* webpackChunkName: "leistungen" */ '../views/Services.vue'),
        children: getServiceRoutes()
    },
    {
        path: '/erfa',
        name: 'ERFA',
        component: () => import(/* webpackChunkName: "leistungen" */ '../views/Erfa.vue')
    }
];
module.exports.routes = routes;

function getRouteList() {
    var routeList = [];
    routes.forEach(routeConfig => {
        routeList = routeList.concat(getRoute(routeConfig));
    });
    return routeList;
};
module.exports.getRouteList = getRouteList;

function getRoute(routeConfig, parentRoute = "") {
    // Build current route
    var currentRoute = parentRoute;
    if (!parentRoute.endsWith("/") && !routeConfig.path.startsWith("/"))
        currentRoute += "/";
    currentRoute += routeConfig.path;

    var routeRoutes = [currentRoute];
    if (routeConfig.children) {
        routeConfig.children.forEach(routeChild => {
            routeRoutes = routeRoutes.concat(getRoute(routeChild, currentRoute));
        });
    }
    return routeRoutes;
};

function getServiceRoutes() {
    return listSites().map(siteConfig => {
        return {
            path: siteConfig.name,
            component: async () => {
                try {
                    return await import(`../views/services/${siteConfig.name}.vue`)
                } catch (ex) {
                    return import(/* webpackChunkName: "fallback" */ '../views/services/Fallback.vue')
                }
            },
        };
    });
}